<template>
  <div>
       <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-4 inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden">
              <table class="min-w-full text-center">
                <thead class="text-xs text-grey text-center bg-primaryLight">
                  <tr class="border  border-white border-opacity-25 text-center">
                    <th scope="col" class="border text-center border-white border-opacity-25 px-6 py-3">شناسه</th>
                    <th scope="col" class="border border-white border-opacity-25 px-6 py-3 text-center">نوع رویداد</th>
                    <th scope="col" class="border border-white border-opacity-25 px-6 py-3 text-center">وضعیت</th>
                    <th scope="col" class="border border-white border-opacity-25 px-6 py-3 text-center">جزئیات</th>
                    <th scope="col" class="border border-white border-opacity-25 px-6 py-3 text-center">زمان</th>
                    <th scope="col" class="border border-white border-opacity-25 px-6 py-3 text-center">عملیات</th>
                  </tr>
                </thead>
                <tbody class="">
                  <tr class="border border-white border-opacity-25 text-center " :class="{'bg-red' : task.queue_info[0].is_alert == 1}" v-for="task in alltaskslist" :key="task">
                    <th scope="row" class="px-6 py-4 whitespace-nowrap text-center">
                      تسک: {{ task.id }}
                      - کاربر: 
                      <router-link :to="'/user?id=' + task.user_id" class="text-white font-bold cursor-pointer"> {{ task.user_id }}</router-link> 
                     
                    </th>
                    <td class="border border-white border-opacity-25 px-6 py-4 text-center"> ماشین {{ task.vm_sequence_id }}  - {{ task.method_name }} - نود: {{ task.node_id }}</td>
                    <td class="border border-white border-opacity-25 px-6 py-4 text-center">{{ task.status_txt }}</td>
                    <td class="border border-white border-opacity-25 px-2 py-4 text-center">
                      {{ task.queue_info[0].status_text }} - تعداد تلاش: {{ task.queue_info[0].retry_count }}
                    </td>
                    <td class="border border-white border-opacity-25 px-6 py-4 text-center">
                      زمان ثبت:
                      <br />
                      {{ task.created_at }}
                      <br />
                      زمان آخرین اجرا:
                      <br />
                      {{ task.queue_info[0].run_time }}
                    
                    </td>
                    <td class="border border-white border-opacity-25 px-6 py-4 text-center gap-4 flex justify-center">
                      <span @click="reRunTask(task.id)" class="cursor-pointer">
                        <feather-icon  icon="RefreshCwIcon"  class="w-6 rotate" />
                      </span>
                      <span @click="cancelTask(task.id)" class="cursor-pointer">
                        <feather-icon icon="StopCircleIcon" class="w-6 rotate" />
                      </span>
                      
                    </td>
                  </tr>
                </tbody>
              </table>
              
              
            </div>
          </div>
        </div>
      </div>
      <vs-pagination cl ass="my-3" :total="allTotal" v-model="alltasksPage"></vs-pagination>
    </div>
  </template>
  
  <script>
  
  export default {
  
    data() {
      return {
        alltasksPage: 1,
        allTotal: 0,
        alltaskslistParams: [],
        alltaskslist:[],
        fields: [
          { key: 'id', label: 'ID' },
          { key: 'method_name', label: 'Method Name' },
          { key: 'status', label: 'Status' },
          { key: 'details', label: 'Details' },
          { key: 'created_at', label: 'Created At' },
          { key: 'actions', label: 'Actions' }
        ]
  
      };
    },
    watch: {
      alltasksPage () {
        this.getAllTasks();
      },
     
    },
    methods: {
     
  
      getAllTasks() {
        this.$http.get(`/admin/cloud/tasks?filter=&page=${this.alltasksPage}&user_id=&vm_id=&node_id=&status=`)
          .then(response => {
            this.alltaskslistParams = response.data.params
            this.allTotal = response.data.params.last_page
            this.alltaskslist = response.data.params.data
          })
          .catch(error => {
            console.error(error)
          })
      },
   
  
      reRunTask(id) {
        this.$http
          .post("/admin/cloud/tasks/rerun",{task_id: id})
          .then((res) => {
            this.$vs.notify({ text: res.data.message });
          })
          .catch((err) => {
            this.$vs.notify({ text: err.response.data.message });
          });
      },
      cancelTask(id) {
        this.$http
          .post("/admin/cloud/tasks/cancel",{task_id: id})
          .then((res) => {
            this.$vs.notify({ text: res.data.message });
          })
          .catch((err) => {
            this.$vs.notify({ text: err.response.data.message });
          });
      }
  
    }, 
    created() {
      this.getAllTasks()
    },
  };
  </script>
  <style scoped>
  .hidescrollbar::-webkit-scrollbar {
    display: none;
  }
  .bg-red {background-color: rgb(255, 72, 72); color: black; font-weight: 800;}

  </style>