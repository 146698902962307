import { render, staticRenderFns } from "./faktorytask.vue?vue&type=template&id=4d95ad82&scoped=true"
import script from "./faktorytask.vue?vue&type=script&lang=js"
export * from "./faktorytask.vue?vue&type=script&lang=js"
import style0 from "./faktorytask.vue?vue&type=style&index=0&id=4d95ad82&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d95ad82",
  null
  
)

export default component.exports